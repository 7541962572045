<template>
  <div class="dsp-chart-wrapper">
    <dashboard-inflator
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions"
      :show-header="false"
      @requestParamsChange="requestParamsChange"
      @metadataUpdated="metadataUpdated"
    />
  </div>
</template>

<script>
import chartingWb from '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue';
import Vue from 'vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
export default {
  components: {},
  mixins: [dashboardMixin],
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    entityConfig: {
      default: () => {},
      type: Object
    }
  },
  data() {
    const pageInfo = {
      name: this.entityConfig.page
    };
    const context = this;
    return {
      context,
      dataService: {},
      pageInfo,
      initRequestParams: {
        global: {
          ...pageInfo,
          ':page': pageInfo.name,
          ':entityType': '#cm_campaign_details'
        },
        [this.entityConfig.widgetName]: this.entityConfig.widgetConfig(
          this.entityConfig.widgetName,
          this.entityId
        )
      }
    };
  },
  computed: {
    headerOptions() {
      return (
        (this.entityConfig &&
          this.entityConfig.headerOptions &&
          this.entityConfig.headerOptions(this.entityConfig.widgetName)) || {
          chartingWb: {
            props: {
              headerOptions: [],
              enableRollup: true,
              enableToggle: true,
              enableDownload: false,
              show: false,
              selectedMetricLimit: 2,
              removeDropdown: true,
              disableAddNewMetricButton: true,
              headerContainerGridStyles: {
                display: 'flex',
                'justify-content': 'flex-end'
              }
            }
          }
        }
      );
    }
  },
  watch: {
    dateFilter: {
      handler(newVal) {
        this.initRequestParams.global = {
          ...this.initRequestParams.global,
          ...newVal
        };
        this.updateEntireRequestParams(this.initRequestParams);
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    if (this.entityConfig?.config?.chartingWb) {
      this.updateChartingWB(this.entityConfig?.config?.chartingWb);
      Vue.component('chartingWb', chartingWb);
    } else if (this.entityConfig?.widgetName) {
      Vue.component(this.entityConfig.widgetName, chartingWb);
    }
  },
  methods: {
    updateChartingWB(newChartingWB) {
      deepReplaceObjectValues(newChartingWB, ':entityId', this.entityId);
      this.initRequestParams.chartingWb = newChartingWB;
    },
    metadataUpdated(data) {
      const metadata = data.filter((item) => item?.metadata?.type === 'tabs');
      metadata.length && this.$emit('tabsMetadata', metadata[0]);
    }
  }
};
</script>

<style lang="css">
.dsp-chart-wrapper .charting_wb_wrapper .header-container-cwc {
  padding-bottom: 0;
}
.dsp-chart-wrapper .charting_wb_wrapper .override-padding {
  padding-top: 1.6rem;
}
</style>
